<template>
  <teleport to="body">
    <div @on-close="handleSmoothClose()">
      <div :class="['mobile-navigation__wrapper', { 'is-open': show }]">
        <section class="mobile-navigation">
          <div class="mobile-navigation__header">
            <p-icon
              icon="close"
              color="foreground-primary"
              class="cursor-pointer"
              size="sm"
              @click="handleSmoothClose()"
            />
          </div>
          <p-divider />
          <div class="mobile-navigation__content">
            <user-details selected @on-close="handleSmoothClose()" />
            <p-divider class="my-16" />
            <div v-for="{ to, title, prepend } in menuItems" :key="title">
              <nuxt-link :to="to" @click="itemClickHandler(to.path)">
                <p-list hover :title="title" :selected="isActive(to.path)">
                  <template #prepend>
                    <p-icon v-if="prepend" :icon="prepend" size="sm" color="foreground-secondary" />
                  </template>
                  <template #append>
                    <p-icon
                      v-if="activeItem === to.path"
                      icon="caret-right"
                      size="xs"
                      color="highlight"
                    />
                  </template>
                </p-list>
              </nuxt-link>
            </div>
            <p-divider class="my-16" />
            <support-list @on-close="handleSmoothClose()" />
          </div>
          <slot />
        </section>
      </div>
    </div>
  </teleport>
</template>

<script setup lang="ts">
const emit = defineEmits(['on-close'])
const route = useRoute()
const show = ref(false)
const activeItem = ref('')

const menuItems = computed((): Record<string, any> => {
  return [
    {
      prepend: 'markets',
      to: { name: 'markets', path: '/markets' },
      title: $t('general.markets'),
    },
    {
      prepend: 'wallet',
      to: { name: 'wallet', path: '/wallet' },
      title: $t('general.wallet'),
    },
    {
      prepend: 'notifications',
      to: { path: '/notifications' },
      title: $t('general.notificationsAndAnnouncements'),
    },
  ]
})

const isActive = (path: string) => route.path === path

onMounted(() => {
  const currentPath = route.path
  const matchingItem = menuItems.value.find(
    (item: { to: { path: string } }) => item.to.path === currentPath,
  )
  if (matchingItem) {
    activeItem.value = currentPath
  }
})

const itemClickHandler = (path: string) => {
  activeItem.value = path
  handleSmoothClose()
}

const handleSmoothClose = (): void => {
  show.value = false
  setTimeout(() => {
    emit('on-close')
  }, 300)
}

const handleSmoothOpen = (): void => {
  setTimeout(() => {
    show.value = true
  }, 100)
}

onMounted(() => {
  handleSmoothOpen()
})
</script>
